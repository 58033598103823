import React, { useEffect, useState } from "react";
import fetchData from "../utils/Fetch";
import { AgGauge } from "ag-charts-react";

function Indicator(props: { symbol: string, name: string, title: string }) {
    const [indicators, setIndicators] = useState([]);

    const [isDarkMode, setIsDarkMode] = React.useState(() => {
        const savedMode = localStorage.getItem('darkMode');
        return savedMode === null ? false : savedMode === 'true';
    });

    useEffect(() => {
        const updateMode = () => {
            setTimeout(() => {
                const savedMode = localStorage.getItem('darkMode');
                if (savedMode) {
                    setIsDarkMode(savedMode === 'true')
                }
            }, 0)
        }
        window.addEventListener('darkModeUpdated', updateMode);
        return () => {
            window.removeEventListener('darkModeUpdated', updateMode);
        };
    }, []);

    useEffect(() => {
        fetchData(`/api/indicators`).then((response) => {
            if (response) {
                setIndicators(response.indicators)
            }
        })
    }, [])

    return (
        <>
                {indicators.filter((indicator: any) => indicator.name === props.name && indicator.symbol === props.symbol).map((indicator: any) => {
                    return (
                        <div className="mini-container" key={indicator.id}>{props.title}
                                <AgGauge style={{display: "block", overflow: "hidden"}} options={{
                                    type: 'radial-gauge',
                                    value: indicator.value,
                                    scale: {
                                        min: 0,
                                        max: 100,
                                        label: {
                                            enabled: false
                                        }
                                    },
                                    startAngle: 180 + 45,
                                    endAngle: 360 + 180 - 45,
                                    background: {
                                        visible: false
                                    },
                                    theme: isDarkMode ? 'ag-polychroma-dark' : 'ag-polychroma',
                                    height: 150,
                                    innerRadiusRatio: 0.8,
                                    label: {
                                        fontSize: 32,
                                    },
                                    bar: {
                                        fill: '#2866f8',
                                    },
                                    needle: {
                                        enabled: false,
                                    },
                                    cornerRadius: 99,
                                    cornerMode: 'container',
                                    segmentation: {
                                        enabled: true,
                                        interval: {
                                            count: 4
                                        },
                                        spacing: 2,
                                    },
                                }}
                                />
                            </div>
                    )
                })}
        </>
)
}

export default Indicator;